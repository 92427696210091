import React from 'react';
import PropTypes from 'prop-types';

import { AuthProvider } from './auth';
import { AnalitycsProvider } from './analitycs';

const AppProvider = ({ children }: any) => (
  <AuthProvider>
    <AnalitycsProvider>{children}</AnalitycsProvider>
  </AuthProvider>
);

AppProvider.defaultProps = {
  children: null,
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default AppProvider;
