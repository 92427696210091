import React, { useState, useEffect } from 'react';

import { ConfigObject } from './types/config';
import { SideMenu, View } from './components';

import person from './assets/person.svg';

import angleLeft from './assets/angle-left.svg';
import bemobiLogo from './assets/logo.svg';
import { useAuth } from './hooks/auth';

const App = () => {
  const [selected, setSelected] = useState<ConfigObject>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [config, setConfig] = useState<ConfigObject[]>();

  const {
    auth: { reports, user },
    logout,
  } = useAuth();

  useEffect(() => {
    setConfig(reports);
  }, [reports]);

  function handleLogout(e: any) {
    e.preventDefault();
    logout();
  }

  if (!config) {
    return null;
  }

  console.log(user);
  return (
    <div className="App">
      <div className={`sidebar ${expanded ? 'inactive' : ''}`}>
        <div
          className={`hideMenu ${expanded ? 'right' : ''}`}
          onClick={() => setExpanded(!expanded)}
        >
          <img
            alt="an arrow to expand and collapse the menu"
            className="angles"
            src={angleLeft}
          />
        </div>
        <header className="profile">
          <img src={user.picture || person} alt="Avatar" />
          <p>{user.name}</p>
          <span>{user['https://bemobi.com.br/tenant']}</span>
        </header>
        <SideMenu options={config} onSelect={c => setSelected(c)} />
        <footer>
          <div className="base-logout">
            <button type="button" onClick={handleLogout}>
              Sair
            </button>
          </div>
          <img src={bemobiLogo} alt="logo da bemobi" className="logo" />
        </footer>
      </div>
      <View config={selected} expanded={expanded} />
    </div>
  );
};

export default App;
