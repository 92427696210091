import React from 'react';

import loading from '../../assets/loading.svg';

import './styles.css';

const Loading: React.FC = () => {
  return (
    <div className="spinner">
      <img src={loading} alt="Loading" />
    </div>
  );
};

export default Loading;
