import React, { useState } from "react"
import filter from "lodash/filter"
import { ConfigObject } from "../types/config"
import { useAnalitycs } from "../hooks/analitycs"

type OptionListProps = {
	options: ConfigObject[]
	onSelect: (config: ConfigObject) => void
}

type Layer = {
	current: ConfigObject[]
	path: string[]
	previous?: Layer
}

const SideMenu: React.FC<OptionListProps> = ({ options, onSelect }) => {

  const { track } = useAnalitycs();

	const [history, setHistory] = useState<Layer>({ current: options, path: [] })
	const [quickfilter, setQuickfilter] = useState<string>("")

	const onClickBtn = (selected: ConfigObject) => {
		if (selected.children) {
			setHistory({ current: selected.children, previous: history, path: [...history.path, selected.name] })
			return
		}

    track('click-link-menu', selected)
		onSelect(selected)
	}

	const goBack = () => {
		if (history.previous) {
			setHistory(history.previous)
		}
	}

	const filtered = filter<ConfigObject>(history.current, (c) => c.name.toLowerCase().indexOf(quickfilter) >= 0)

	return (
  <div className="menu">
    <input
      onChange={(e) => {
					setQuickfilter(e.target.value.toLowerCase())
				}}
      value={quickfilter}
      className="search"
      placeholder="Buscar"
    />
    <ol>
      {history.previous && (
        <li className="menu-item">
          <button onClick={() => goBack()}>Voltar</button>
        </li>
      )}
      {filtered.map((c, idx) => (
        <li key={idx} className="menu-item">
          <button onClick={() => onClickBtn(c)}>
            {c.name}
            {c.children ? <ChildrenIndicator /> : null}
          </button>
        </li>
      ))}
    </ol>
    <CustomBreadcrumb path={history.path} />
  </div>
	)
}

const ChildrenIndicator: React.FC = () => {
	return <div className="children-indicator">></div>
}

const CustomBreadcrumb: React.FC<{ path: string[] }> = ({ path }) => {
	const amount = path.length
	const displayed = amount > 2 ? `...>${path[amount - 2]}>${path[amount - 1]}` : path.join(">")
	return (
  <b title={path.join(">")} className="breadcrumb">
    {displayed}
  </b>
	)
}

export default SideMenu
