import React, {
  createContext,
  useContext,
  useEffect,
  ReactNode,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import * as rudderanalytics from 'rudder-sdk-js';
import Loading from '../components/Loading';
import { useAuth } from './auth';

interface Props {
  children: ReactNode;
}

interface ResultProps {
  track: (event: string, params: any) => void;
}

const AnalitycsContext = createContext({} as ResultProps);

export const AnalitycsProvider = ({ children }: Props): any => {
  const [loading, setLoading] = useState(true);
  const [identifySuccess, setIdentifySuccess] = useState(false);

  const {
    auth: { user },
  } = useAuth();

  function identify() {
    const { sub, email, ...rest } = user;

    if (!identifySuccess) {
      rudderanalytics.identify(
        sub,
        {
          ...rest
        },
        null,
        () => {
          console.log('ok ok');
          setIdentifySuccess(true);
        },
      );

      rudderanalytics.alias(email);

      track('LoggedIn', {});
    }
  }

  useEffect(() => {
    function loadSdk() {
      setLoading(true);

      rudderanalytics.load(
        '1jBPkWUEr5F7l6sCmJBOOuMhDP8',
        process.env.NODE_ENV === 'development'
          ? '/analitycs'
          : 'https://cdp.bemobi.com/sdk',
        {configUrl: 'https://cdp.bemobi.com/sdk'}
      );
      identify();
      setLoading(false);
    }
    loadSdk();
  }, []);

  function track(event: string, params: any): void {
    rudderanalytics.track(
      event,
      {
        ...params,
      },
      () => {
        console.log('track success');
      },
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <AnalitycsContext.Provider value={{ track }}>
      {children}
    </AnalitycsContext.Provider>
  );
};

AnalitycsProvider.defaultProps = {
  children: null,
};

AnalitycsProvider.propTypes = {
  children: PropTypes.node,
};

export function useAnalitycs(): ResultProps {
  const context = useContext(AnalitycsContext);
  if (!context) {
    throw new Error('useAnalitycs must be used within an AnalitycsProvider');
  }
  return context;
}
