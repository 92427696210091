import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import providerConfig from './auth0';

import './index.css';

import App from './App';

import AppProvider from './hooks';

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <AppProvider>
      <App />
    </AppProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);
