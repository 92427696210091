import React from 'react';
import { ConfigObject } from '../types/config';

type ViewProps = {
  config: ConfigObject | undefined;
  expanded: boolean;
};

const View: React.FC<ViewProps> = ({ config, expanded }) => {
  if (!config || !config.url) {
    return <span />;
  }

  return (
    <div className={`report ${expanded ? 'expanded' : ''}`}>
      <iframe
        title={config.name}
        src={`${config.url}${config.additionalArgs || ''}`}
        frameBorder={0}
      />
    </div>
  );
};

export default View;
