import axios from 'axios';

const apiReports = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? './reports'
      : 'https://ya24gnwhj2.execute-api.us-east-1.amazonaws.com/reports',
});

export default apiReports;
