import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import apiReports from '../services/apiReports';
import Loading from '../components/Loading';
import { ConfigObject } from '../types/config';
import { UserAuth } from '../types/userAuth';

interface Auth {
  user: UserAuth;
  reports: ConfigObject[];
}

interface ResultProps {
  auth: Auth;
  logout: () => any;
}

const AuthContext = createContext({} as ResultProps);

const Provider = ({ children }: any) => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState<Auth>({ user: {} as UserAuth, reports: [] });

  const { user, getIdTokenClaims, logout } = useAuth0();

  useEffect(() => {
    async function validateUser() {
      const { __raw: token } = await getIdTokenClaims();
      try {
        const { data: reports } = await apiReports.get('', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAuth({ user, reports });
      } catch (e) {
        console.error('error', e);
      }
      setLoading(false);
    }
    if (user) {
      validateUser();
    }
  }, [user, getIdTokenClaims]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider value={{ auth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

Provider.defaultProps = {
  children: null,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export const AuthProvider = withAuthenticationRequired(Provider, {
  onRedirecting: () => <Loading />,
});

export function useAuth(): ResultProps {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
